import { Component, OnInit } from '@angular/core';
import { MatAnchor, MatButton } from '@angular/material/button';
import { applyActionCode, Auth } from '@angular/fire/auth';
import { ActivatedRoute } from '@angular/router';
import { NgIf } from '@angular/common';
import { MatProgressSpinner } from '@angular/material/progress-spinner';

enum EmailVerificationState {
  Pending,
  Successful,
  Failed
}

@Component({
  selector: 'app-email-verification',
  standalone: true,
  imports: [MatButton, NgIf, MatProgressSpinner, MatAnchor],
  templateUrl: './email-verification.component.html',
  styleUrl: './email-verification.component.scss'
})
export class EmailVerificationComponent implements OnInit {
  state: EmailVerificationState = EmailVerificationState.Pending;

  EmailVerificationState = EmailVerificationState;

  constructor(
    private route: ActivatedRoute,
    private auth: Auth
  ) {}
  
  async ngOnInit() {
    const code: string | null = this.route.snapshot.queryParamMap.get('code');

    if (!code) {
      this.state = EmailVerificationState.Failed;
      return;
    }

    try {
      await applyActionCode(this.auth, code);
      this.state = EmailVerificationState.Successful;
    } catch {
      this.state = EmailVerificationState.Failed;
    }
  }
}
