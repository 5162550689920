import { Component, OnInit } from '@angular/core';
import { MatButton, MatAnchor } from '@angular/material/button';
import { Auth, confirmPasswordReset, verifyPasswordResetCode } from '@angular/fire/auth';
import { ActivatedRoute } from '@angular/router';
import { NgIf } from '@angular/common';
import { MatFormField, MatLabel, MatError } from '@angular/material/form-field';
import { MatInput } from '@angular/material/input';
import { MatProgressSpinner } from '@angular/material/progress-spinner';
import { ToastrService } from 'ngx-toastr';
import { FormControl, ReactiveFormsModule, Validators } from '@angular/forms';

enum PasswordResetState {
  ValidatingCode = 'ValidatingCode',
  InvalidCode = 'InvalidCode',
  RequiresPassword = 'RequiresPassword',
  SuccessfullySavedPassword = 'SuccessfullySavedPassword'
}

@Component({
  selector: 'app-password-reset',
  standalone: true,
  imports: [MatButton, MatAnchor, NgIf, MatFormField, MatLabel, MatInput, MatError, MatProgressSpinner, ReactiveFormsModule],
  templateUrl: './password-reset.component.html',
  styleUrl: './password-reset.component.scss'
})
export class PasswordResetComponent implements OnInit {
  state: PasswordResetState = PasswordResetState.ValidatingCode;
  code: string | null = null;
  emailAddress: string | null = null;
  isSavingInProgress: boolean = false;

  passwordControl: FormControl = new FormControl('', [Validators.required, Validators.minLength(6)]);

  PasswordResetState = PasswordResetState;

  constructor(
    private route: ActivatedRoute,
    private auth: Auth,
    private toastr: ToastrService
  ) {}
  
  async ngOnInit() {
    this.code = this.route.snapshot.queryParamMap.get('code');

    if (!this.code) {
      this.state = PasswordResetState.InvalidCode;
      return;
    }

    try {
      this.emailAddress = await verifyPasswordResetCode(this.auth, this.code);
      this.state = PasswordResetState.RequiresPassword;
    } catch {
      this.state = PasswordResetState.InvalidCode;
    }
  }

  async setPassword() {
    if (this.passwordControl.invalid) {
      this.passwordControl.markAsTouched();
      return;
    }

    try {
      this.isSavingInProgress = true;
      await confirmPasswordReset(this.auth, this.code!, this.passwordControl.value);
      this.state = PasswordResetState.SuccessfullySavedPassword;
    } catch {
      this.isSavingInProgress = false;
      this.toastr.error('Something went wrong. Please try again later.');
    }
  }
}
