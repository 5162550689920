<div class="box mat-elevation-z8">
  <h1 class="box__title" *ngIf="state !== EmailVerificationState.Pending">
    <ng-container *ngIf="state === EmailVerificationState.Successful">
      Successful verification
    </ng-container>

    <ng-container *ngIf="state === EmailVerificationState.Failed">
      Try verifying your email again
    </ng-container>
  </h1>

  <div class="box__content">
    <mat-spinner *ngIf="state === EmailVerificationState.Pending"></mat-spinner>

    <ng-container *ngIf="state === EmailVerificationState.Successful">
      <p>
        Your email has been verified. You can now sign in with your new account.
      </p>

      <br/>

      <a mat-flat-button href="datePlanner://landing" target="_blank">
        Click Here to Go Back to the LoveTrack App
      </a>
    </ng-container>

    <p *ngIf="state === EmailVerificationState.Failed">
      Your request to verify your email has expired or the link has already been used.
    </p>
  </div>
</div>