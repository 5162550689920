import { CanActivateFn, Routes } from '@angular/router';
import { EmailVerificationComponent } from './features/email-verification/email-verification.component';
import { BoxLayoutComponent } from './core/layouts/box-layout/box-layout.component';
import { PasswordResetComponent } from './features/password-reset/password-reset.component';

export const routes: Routes = [
  {
    path: '',
    component: BoxLayoutComponent,
    children: [
      { path: '', pathMatch: 'full', canActivate: [redirectGuard()], component: EmailVerificationComponent },
      { path: 'email-verification', component: EmailVerificationComponent },
      { path: 'password-reset', component: PasswordResetComponent }
    ]
  },
  { path: '**', canActivate: [redirectGuard()], component: EmailVerificationComponent }
];

export function redirectGuard(): CanActivateFn {
  return () => {
    window.location.href = 'https://lovetrackapp.com';
    return false;
  };
}