<div class="box mat-elevation-z8">
  <h1 class="box__title" *ngIf="state !== PasswordResetState.ValidatingCode">
    <ng-container *ngIf="state === PasswordResetState.InvalidCode">
      Try resetting your password again
    </ng-container>

    <ng-container *ngIf="state === PasswordResetState.RequiresPassword">
      Reset your password
    </ng-container>

    <ng-container *ngIf="state === PasswordResetState.SuccessfullySavedPassword">
      Password changed
    </ng-container>
  </h1>

  <div class="box__content">
    <mat-spinner *ngIf="state === PasswordResetState.ValidatingCode"></mat-spinner>

    <p *ngIf="state === PasswordResetState.InvalidCode">
      Your request to reset your password has expired or the link has already been used.
    </p>

    <mat-form-field *ngIf="state === PasswordResetState.RequiresPassword" appearance="outline">
      <mat-label>New password</mat-label>
      <input matInput type="password" [formControl]="passwordControl" placeholder="Enter your password here">
      <mat-error *ngIf="passwordControl.hasError('required')">Please enter a value</mat-error>
      <mat-error *ngIf="passwordControl.hasError('minlength')">Please enter at least 6 characters</mat-error>
    </mat-form-field>

    <ng-container *ngIf="state === PasswordResetState.SuccessfullySavedPassword">
      <p>
        Your password has been successfully changed. You can now sign in with your new password.
      </p>

      <br/>

      <a mat-flat-button href="datePlanner://landing" target="_blank">
        Click Here to Go Back to the LoveTrack App
      </a>
    </ng-container>
  </div>

  <div *ngIf="state === PasswordResetState.RequiresPassword" class="box__actions">
    <button mat-flat-button (click)="setPassword()" [disabled]="isSavingInProgress">
      Set Password
    </button>
  </div>
</div>